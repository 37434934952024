import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiAssetCondition,
  ApiAttachment,
  ApiRequest,
  Label,
  UpdateApiAsset,
} from "@operations-hero/lib-api-client";
import axios, { AxiosProgressEvent } from "axios";
import { Form, Formik } from "formik";
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoIosCamera } from "react-icons/io";
import { MdEmail, MdPhone } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AttachmentFileCard } from "../../../components/attachments/AttachmentFileCard";
import {
  Attachment,
  Attachments,
  mapApiAttachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AssetConditionBadge } from "../../../components/badges/AssetConditionBadge";
import { AssetWarrantyBadge } from "../../../components/badges/AssetWarrantyBadge";
import { DateBadge } from "../../../components/badges/DateBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { AssetConditionSelectControl } from "../../../components/form-helpers/AssetConditionSelectControl";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { LocationAutocompleteControl } from "../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../components/form-helpers/NumberInputControl";
import { QrTextInputControl } from "../../../components/form-helpers/QrTextInputControl";
import { ReadOnlyTextEditor } from "../../../components/form-helpers/rich-text-editor/RichTextEditorReadOnly";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { VendorAutocompleteControl } from "../../../components/form-helpers/VendorsAutocompleteControl";
import { SparkleWithAnimation } from "../../../components/icons/Sparkle";
import { useShowToast } from "../../../hooks/showToast";
import { RootState } from "../../../store";
import { setAiAsset } from "../../../store/ai-assets/aiAsset.slice";
import { updateRequestAsset } from "../../../store/request-form/request-form.slice";
import { formatCurrency } from "../../../utils/formatCurrency";
import { CreatePictureModal } from "../../account-settings/asset-list/CreateByPictureModal";
import { LabelsSection } from "../../account-settings/location-form/LabelsSection";
import { useIsReviewerOrTechnicianOfWorkflows } from "../../account-settings/routes/routes-helpers/policies-checks";
import { useIsAllowManageAssetForTech } from "../../account-settings/routes/routes-helpers/settings-checks";

export interface AssetDetailModalProps {
  asset: ApiAsset;
  request?: ApiRequest;
  onClose: () => void;
  isEditingAsset?: boolean;
}
const AssetSchema = yup.object().shape({
  name: yup.string().max(255, "Field is too long").required("Name is required"),
  externalId: yup.string().max(255, "Field is too long").optional().nullable(),
  location: yup.object().nullable(),
  reportingCategory: yup.object().nullable(),
  manufacturer: yup.object().optional().nullable(),
  serviceProvider: yup.object().optional().nullable(),
  vendorName: yup.object().optional().nullable(),
  model: yup.string().max(255, "Field is too long").optional().nullable(),
  serial: yup.string().max(255, "Field is too long").optional().nullable(),
  notes: yup.string().max(2000, "Field is too long").optional().nullable(),
  condition: yup
    .string()
    .optional()
    .nullable()
    .oneOf(Object.values(ApiAssetCondition)),
  purchaseCost: yup
    .number()
    .min(0)
    .typeError("Must be a number")
    .nullable()
    .optional(),
  purchaseOrder: yup
    .string()
    .max(2000, "Field is too long")
    .optional()
    .nullable(),
  invoiceNumber: yup
    .string()
    .max(2000, "Field is too long")
    .optional()
    .nullable(),
  purchaseDate: yup.date().optional().nullable(),
  inServiceDate: yup.date().optional().nullable(),
  warrantyExpiration: yup.date().optional().nullable(),
  targetRenewalDate: yup.date().optional().nullable(),
  defaultImage: yup.object().nullable(),
});

const FormField = ({
  label,
  children,
  index,
}: {
  label: string;
  children: React.ReactNode;
  index: number;
}) => (
  <Grid
    bg={index % 2 === 0 ? "gray.50" : "white"}
    templateColumns="1fr 3fr"
    gap={4}
    alignItems="center"
  >
    <GridItem>
      <Text pl={4} pt={1} pb={1}>
        {label}
      </Text>
    </GridItem>

    <GridItem pl={4} pt={1} pb={1} pr={3}>
      {children}
    </GridItem>
  </Grid>
);

export const AssetDetailModal = ({
  asset,
  request,
  onClose,
  isEditingAsset,
}: AssetDetailModalProps) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const { apiClient, currentAccount: account } = useAuthentication();
  const isOpen = useMemo(() => !!asset, [asset]);
  const [isEditing, setIsEditing] = useState(isEditingAsset ?? false);
  const [isUploading, setIsUploading] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [defaultImage, setDefaultImage] = useState<ApiAttachment | null>(
    asset.defaultImage
  );

  const [defaultPhoto, setDefaultPhoto] = useState<Attachment>();
  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );
  const [newAttachments, setNewAttachments] = useState<Attachment[]>([]);
  const linkColor = useColorModeValue("blue.500", "blue.300");
  const bannerBgColor = useColorModeValue("gray.200", "gray.800");
  const bannerFontColor = useColorModeValue("gray.500", "gray.400");
  const { isReviewerOrTech } = useIsReviewerOrTechnicianOfWorkflows();
  const { isAllowManageAssetForTech } = useIsAllowManageAssetForTech();
  const { isProductAdmin } = useAuthentication();

  const { apiAsset } = useSelector((state: RootState) => state.aiAssetSlice);
  const handleEditButton = () => {
    setIsEditing(!isEditing);
  };

  const canEditAsset = useMemo(() => {
    return (isReviewerOrTech && isAllowManageAssetForTech) || isProductAdmin;
  }, [isReviewerOrTech, isAllowManageAssetForTech, isProductAdmin]);

  const {
    isOpen: isCreateByPictureModalOpen,
    onOpen: onOpenCreateByPictureModal,
    onClose: onCloseCreateByPictureModal,
  } = useDisclosure();

  const handleSubmit = useCallback(
    async (values: any) => {
      if (!account || !asset) return;
      try {
        const deletedAttachments = attachments.filter((a) => {
          return workingAttachments.every((wa) => a.id !== wa.id);
        });

        if (
          asset.defaultImage &&
          deletedAttachments.findIndex((x) => x.id === asset.defaultImage!.id) >
            -1
        ) {
          values.defaultImage = null;
        }

        let newAsset: UpdateApiAsset = {
          ...values,
          labels: apiAsset ? apiAsset.labels : [],
        };

        let updatedAsset = await apiClient.updateAsset(
          account.id,
          asset.id,
          newAsset
        );

        deletedAttachments.forEach(async (a) => {
          await apiClient.deleteAssetAttachment(account.id, asset.id, a.id!);
        });

        let didUpdatedDefaultPhoto = false;

        let attachmentSavePromises = newAttachments.map(async (attachment) => {
          if (attachment.uploadId === undefined) {
            return Promise.resolve();
          }

          let newAttachment = await apiClient.createAssetAttachment(
            account.id,
            updatedAsset.id,
            {
              uploadId: attachment.uploadId,
              name: attachment.name,
            }
          );

          if (attachment.uploadId === defaultPhoto?.uploadId) {
            updatedAsset = await apiClient.updateAsset(
              account.id,
              updatedAsset.id,
              {
                defaultImage: newAttachment,
              }
            );
            newAsset.defaultImage = updatedAsset.defaultImage;
            didUpdatedDefaultPhoto = true;
          }
        });

        await Promise.all(attachmentSavePromises);

        if (!didUpdatedDefaultPhoto && defaultPhoto) {
          const newDefaultImageId = defaultPhoto?.id
            ? defaultPhoto.id
            : workingAttachments.find((a) => a && a.type.indexOf("image/") > -1)
                ?.id;
          if (
            newDefaultImageId &&
            newDefaultImageId !== updatedAsset.defaultImage?.id
          ) {
            updatedAsset = await apiClient.updateAsset(
              account.id,
              updatedAsset.id,
              {
                defaultImage: newDefaultImageId,
              }
            );
            newAsset.defaultImage = updatedAsset.defaultImage;
          }
        }
        dispatch(updateRequestAsset(updatedAsset));
        showToast("success", "Asset updated successfully");
      } catch (error) {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Error updating asset",
        });
      } finally {
        setIsEditing(false);
        dispatch(setAiAsset({ apiAsset: null }));
        onClose();
      }
    },
    [
      apiClient,
      account,
      asset,
      dispatch,
      toast,
      showToast,
      onClose,
      defaultPhoto,
      newAttachments,
      workingAttachments,
      apiAsset,
      attachments,
    ]
  );

  const handleSetLabels = useCallback(
    (labels: Label[]) => {
      dispatch(setAiAsset({ apiAsset: { ...asset, labels } }));
    },
    [dispatch, asset]
  );

  const browseFiles = useCallback(() => {
    if (!fileRef.current) return;
    fileRef.current.click();
  }, [fileRef]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }
      const rawFile = event.target.files[0];
      setIsUploading(true);

      apiClient
        .createUpload(account.id)
        .then(async (upload) => {
          await axios.put(upload.url, rawFile, {
            headers: { "Content-Type": rawFile.type },
          });

          const updated = await apiClient.createAssetAttachment(
            account.id,
            asset.id,
            {
              uploadId: upload.id,
            }
          );

          const updatedAsset = await apiClient.updateAsset(
            account.id,
            asset.id,
            { defaultImage: updated }
          );

          setDefaultImage(updated);

          if (request) {
            dispatch(updateRequestAsset(updatedAsset));
          }

          if (fileRef.current) {
            fileRef.current.value = "";
          }
        })
        .catch(() => {
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "error",
            title: "Error updating logo",
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [apiClient, asset, account, toast, request, dispatch]
  );

  const handleAddAttachment = useCallback(
    (files: Attachment[]) => {
      let allAttachments = [...newAttachments];

      files.forEach(async (item) => {
        const response = await apiClient.createUpload(account.id);
        const newAttachment: Attachment = {
          ...item,
          isNew: true,
          isUploading: true,
          progress: 0,
          uploadId: response.id,
        };
        allAttachments.push(newAttachment);

        setNewAttachments(allAttachments);

        await axios.put(response.url, newAttachment.file, {
          headers: { "Content-type": newAttachment.file?.type },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            newAttachment.progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );

            setNewAttachments([...allAttachments]);
          },
        });

        newAttachment.progress = undefined;
        newAttachment.isUploading = false;
        setNewAttachments([...allAttachments]);
      });
    },
    [apiClient, account, newAttachments]
  );

  const handleDeleteAttachment = useCallback(
    (file: Attachment) => {
      const updatedAttachments = [...workingAttachments];
      const index = updatedAttachments.findIndex(
        (f) => f.uploadId === file.uploadId
      );
      updatedAttachments.splice(index, 1);

      setWorkingAttachments(updatedAttachments);
    },
    [workingAttachments]
  );

  const setDefaultPhotoState = useCallback((attachment: Attachment) => {
    setDefaultPhoto(attachment);
  }, []);

  useEffect(() => {
    apiClient
      .findAssetAttachments(account.id, asset.id, { pageSize: 20 })
      .then((result) => {
        setAttachments(mapApiAttachments(result.data));
        setWorkingAttachments([
          ...mapApiAttachments(result.data),
          ...newAttachments,
        ]);
      });
  }, [apiClient, account.id, asset.id, newAttachments]);

  useEffect(() => {
    dispatch(setAiAsset({ apiAsset: asset }));
  }, [asset, dispatch]);

  return isOpen ? (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent maxW="2xl">
        <ModalBody p={0}>
          <Stack align="flex-start" spacing={0}>
            <Box
              height={["200px", "250px"]}
              width="100%"
              position={"relative"}
              backgroundColor={bannerBgColor}
              overflow="hidden"
              borderTopRadius={"lg"}
              cursor={defaultImage ? undefined : "pointer"}
              onClick={defaultImage ? undefined : browseFiles}
            >
              {defaultImage ? (
                <Image
                  src={defaultImage.url}
                  alt={defaultImage.name}
                  transform="translate(-50%, -50%)"
                  minH="100%"
                  minW="100%"
                  position="absolute"
                  boxSizing="border-box"
                  left="50%"
                  top="50%"
                  objectFit="cover"
                />
              ) : (
                <Center height={"100%"} color={bannerFontColor}>
                  {isUploading ? (
                    <Spinner />
                  ) : (
                    <>
                      <Icon as={IoIosCamera} w={8} h={8} />
                      <Text ml={2}>Add asset image</Text>
                    </>
                  )}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileRef}
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg"
                  />
                </Center>
              )}
            </Box>
            <HStack width={"100%"}>
              {!isEditing ? (
                <>
                  <Text pl={4} pt={4} pb={4} fontSize="xl" fontWeight="bold">
                    Asset Details
                  </Text>
                  <Spacer />
                  {canEditAsset && (
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={handleEditButton}
                    >
                      Update Manually
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Text pl={4} pt={4} pb={4} fontSize="xl" fontWeight="bold">
                    Edit Asset
                  </Text>
                  <Spacer />
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={handleEditButton}
                  >
                    Cancel
                  </Button>
                </>
              )}
              {canEditAsset && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant="outline"
                  ml={3}
                  onClick={onOpenCreateByPictureModal}
                  mr={4}
                >
                  Update with AI <SparkleWithAnimation />
                </Button>
              )}
            </HStack>
            {isEditing ? (
              <Stack align="flex-start" spacing={0} width="100%">
                <Flex
                  width="100%"
                  position={"relative"}
                  overflow="hidden"
                  borderTopRadius={"lg"}
                >
                  {" "}
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={asset}
                    validationSchema={AssetSchema}
                  >
                    {({ values }) => (
                      <Form id="asset-form">
                        <FormField label="Name *" index={0}>
                          <TextInputControl
                            name="name"
                            value={values.name}
                            placeholder="Asset Name"
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Tag / Alternate ID *" index={1}>
                          <QrTextInputControl
                            name="externalId"
                            value={values.externalId}
                            placeholder="i.e. HS1-AHU-100-3"
                            helperText="Reuse existing labels, barcodes or QR codes"
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Location" index={0}>
                          <LocationAutocompleteControl
                            value={values.location}
                            name="location"
                            allowEmpty={true}
                            isDisabled={!asset.active}
                            label={""}
                          />
                        </FormField>
                        <FormField label="Condition" index={1}>
                          <AssetConditionSelectControl
                            name="condition"
                            value={values.condition}
                            isDisabled={!asset.active}
                            label={""}
                          />
                        </FormField>
                        <FormField label="Supplier" index={0}>
                          <Box>
                            <VendorAutocompleteControl
                              name="vendor"
                              isSupplier
                              label=""
                              addVendorLink
                              value={values.vendor}
                            />
                          </Box>
                        </FormField>
                        <FormField label="Manufacturer" index={1}>
                          <VendorAutocompleteControl
                            addVendorLink
                            isManufacturer
                            name="manufacturer"
                            label=""
                            value={values.manufacturer}
                          />
                        </FormField>
                        <FormField label="Service Provider" index={0}>
                          <VendorAutocompleteControl
                            addVendorLink
                            isServiceProvider
                            name="serviceProvider"
                            label=""
                            value={values.serviceProvider}
                          />
                        </FormField>
                        <FormField label="Model" index={1}>
                          <TextInputControl
                            name="model"
                            value={values.model}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Serial" index={0}>
                          <TextInputControl
                            name="serial"
                            value={values.serial}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>

                        <FormField label="In Service Date" index={1}>
                          <DatePickerControl
                            name="inServiceDate"
                            value={values.inServiceDate}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Warranty Expires" index={0}>
                          <DatePickerControl
                            name="warrantyExpiration"
                            value={values.warrantyExpiration}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Estimated Renewal Date" index={1}>
                          <DatePickerControl
                            name="targetRenewalDate"
                            value={values.targetRenewalDate}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Purchase Date" index={0}>
                          <DatePickerControl
                            name="purchaseDate"
                            value={values.purchaseDate}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <FormField label="Purchase Cost" index={1}>
                          <NumberInputControl
                            prefix="$"
                            name="purchaseCost"
                            value={values.purchaseCost}
                            placeholder=""
                            isDisabled={!asset.active}
                            min={0}
                          />
                        </FormField>
                        <FormField label="Purchase Order" index={0}>
                          <TextInputControl
                            name="purchaseOrder"
                            value={values.purchaseOrder}
                            label=""
                            placeholder=""
                            isDisabled={!asset.active}
                          />
                        </FormField>
                        <Box p={3} pb={3}>
                          <Text>Labels</Text>
                          <LabelsSection
                            isNameDisabled={true}
                            type="asset"
                            value={apiAsset?.labels || []}
                            setValues={handleSetLabels}
                          />
                        </Box>

                        <Divider />
                        <Box p={3} pt={3}>
                          <Attachments
                            getDefaultPhoto={setDefaultPhotoState}
                            showThumbnailEdit={true}
                            attachments={workingAttachments}
                            onNewAttachments={handleAddAttachment}
                            onDeleteAttachment={handleDeleteAttachment}
                            title="Attachments"
                            gridColumns={[2, null, 3, null, 4]}
                            isDisabled={!asset.active}
                          />
                        </Box>

                        <Flex justifyContent="flex-end" gap={3} pt={3} pr={3}>
                          <Button
                            colorScheme="blue"
                            onClick={handleEditButton}
                            size="sm"
                            variant="outline"
                            pr={3}
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="blue"
                            type="submit"
                            form="asset-form"
                            size="sm"
                            isLoading={isUploading}
                          >
                            Update Asset
                          </Button>
                        </Flex>
                      </Form>
                    )}
                  </Formik>
                </Flex>
              </Stack>
            ) : (
              <Stack width={"100%"} spacing={0}>
                <Property
                  label="Name"
                  value={<Text fontSize="lg">{asset.name}</Text>}
                />
                {asset.externalId && (
                  <Property
                    label="Tag / Alternate ID"
                    value={asset.externalId || ""}
                  />
                )}
                <Property
                  label="Location"
                  value={
                    asset.location ? (
                      <LocationTwoLine value={asset.location} />
                    ) : (
                      "No Location / Mobile Asset"
                    )
                  }
                />
                {asset.notes && (
                  <Property
                    label="Notes"
                    value={<ReadOnlyTextEditor value={asset.notes} />}
                  />
                )}
                {asset.condition && (
                  <Property
                    label="Condition"
                    value={<AssetConditionBadge value={asset.condition} />}
                  />
                )}

                {asset.vendor && (
                  <Property
                    label="Supplier"
                    value={
                      <Flex flexDir="column">
                        <Text>{asset.vendor.name}</Text>
                        <Flex
                          justifyContent="space-between"
                          w="100%"
                          wrap="wrap"
                        >
                          <Link
                            href={`mailto::${asset.vendor.primaryContact?.email}`}
                            color={linkColor}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={MdEmail} mr={1} />
                            {asset.vendor.primaryContact?.email}
                          </Link>

                          <Flex gap={2}>
                            <Link
                              href={`tel::${asset.vendor.primaryContact?.phone}`}
                              color={linkColor}
                              display="flex"
                              alignItems="center"
                            >
                              <Icon as={MdPhone} mr={1} />
                              {asset.vendor.primaryContact?.phone}
                            </Link>

                            {asset.vendor.primaryContact?.altPhone && (
                              <Link
                                href={`tel::${asset.vendor.primaryContact.altPhone}`}
                                color={linkColor}
                                display="flex"
                                alignItems="center"
                              >
                                <Icon as={MdPhone} mr={1} />
                                {asset.vendor.primaryContact.altPhone}
                              </Link>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                    }
                  />
                )}

                {asset.manufacturer && (
                  <Property
                    label="Manufacturer"
                    value={
                      <Flex flexDir="column">
                        <Text>{asset.manufacturer.name}</Text>
                        {asset.manufacturer.primaryContact?.email && (
                          <Link
                            href={`mailto:${asset.manufacturer.primaryContact?.email}`}
                            color={linkColor}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={MdEmail} mr={1} />
                            {asset.manufacturer.primaryContact?.email}
                          </Link>
                        )}
                        {asset.manufacturer.primaryContact?.altPhone && (
                          <Link
                            href={`tel::${asset.manufacturer.primaryContact.altPhone}`}
                            color={linkColor}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={MdPhone} mr={1} />
                            {asset.manufacturer.primaryContact.altPhone}
                          </Link>
                        )}
                      </Flex>
                    }
                  />
                )}
                {asset.model && (
                  <Property label="Model" value={asset.model || ""} />
                )}
                {asset.serial && (
                  <Property label="Serial" value={asset.serial || ""} />
                )}
                {asset.serviceProvider && (
                  <Property
                    label="Service Provider"
                    value={
                      <Flex flexDir="column">
                        <Text>{asset.serviceProvider.name}</Text>
                        {asset.serviceProvider.primaryContact?.email && (
                          <Link
                            href={`mailto:${asset.serviceProvider.primaryContact?.email}`}
                            color={linkColor}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={MdEmail} mr={1} />
                            {asset.serviceProvider.primaryContact?.email}
                          </Link>
                        )}
                        {asset.serviceProvider.primaryContact?.altPhone && (
                          <Link
                            href={`tel::${asset.serviceProvider.primaryContact.altPhone}`}
                            color={linkColor}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={MdPhone} mr={1} />
                            {asset.serviceProvider.primaryContact.altPhone}
                          </Link>
                        )}
                      </Flex>
                    }
                  />
                )}

                {asset.inServiceDate && (
                  <Property
                    label="In Service Date"
                    value={<DateBadge date={asset.inServiceDate} />}
                  />
                )}
                {asset.warrantyExpiration && (
                  <Property
                    label="Warranty Expires"
                    value={
                      <HStack spacing={4}>
                        <Box>
                          <DateBadge date={asset.warrantyExpiration} />
                        </Box>
                        <Box>
                          <AssetWarrantyBadge asset={asset} />
                        </Box>
                      </HStack>
                    }
                  />
                )}
                {asset.targetRenewalDate && (
                  <Property
                    label="Estimated Renewal"
                    value={<DateBadge date={asset.targetRenewalDate} />}
                  />
                )}
                {asset.purchaseDate && (
                  <Property
                    label="Purchase Date"
                    value={<DateBadge date={asset.purchaseDate} />}
                  />
                )}
                {asset.purchaseCost && (
                  <Property
                    label="Purchase Cost"
                    value={formatCurrency(asset.purchaseCost)}
                  />
                )}
                {asset.purchaseOrder && (
                  <Property
                    label="Purchase Order"
                    value={asset.purchaseOrder}
                  />
                )}
                {asset.invoiceNumber && (
                  <Property
                    label="Invoice Number"
                    value={asset.invoiceNumber}
                  />
                )}
                {asset.labels && asset.labels.length > 0 && (
                  <Property
                    label="Labels"
                    value={
                      <Fragment>
                        {asset.labels.map((label, index) => {
                          return (
                            <Flex
                              justifyContent="space-between"
                              w="70%"
                              wrap="wrap"
                              key={`labels::${index}${label.key}${label.value}`}
                            >
                              <Text fontWeight="bold">{label.key}</Text>
                              <Text fontWeight="bold">{label.value}</Text>
                            </Flex>
                          );
                        })}
                      </Fragment>
                    }
                  />
                )}
                {attachments && attachments.length && (
                  <>
                    <Wrap p={4}>
                      {attachments.map((attachment: Attachment) => (
                        <WrapItem
                          key={`asset-attachment-${attachment.uploadId}`}
                        >
                          <AttachmentFileCard attachment={attachment} />
                        </WrapItem>
                      ))}
                    </Wrap>
                  </>
                )}
              </Stack>
            )}
            ;
          </Stack>

          {isCreateByPictureModalOpen && (
            <CreatePictureModal
              isOpen={isCreateByPictureModalOpen}
              onClose={onCloseCreateByPictureModal}
            />
          )}
        </ModalBody>
        <ModalFooter textAlign={"right"}>
          {!isEditing ? (
            <Button
              autoFocus={false}
              colorScheme="blue"
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          ) : (
            <></>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
};

interface Props extends FlexProps {
  label: string;
  value: string | JSX.Element;
}

export const Property = (props: Props) => {
  const { label, value, ...flexProps } = props;
  return (
    <Flex
      direction={{ base: "column", sm: "row" }}
      px="4"
      py="4"
      mt={0}
      _even={{ bg: useColorModeValue("gray.50", "gray.600") }}
      {...flexProps}
    >
      <Box minWidth="180px">{label}</Box>
      <Box flex="1">{value}</Box>
    </Flex>
  );
};
