import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { combineReducers } from "redux";
import accountUsers from "./account-users.slice";
import aiAssetSlice from "./ai-assets/aiAsset.slice";
import announcement from "./announcement-slice";
import assetList from "./asset-list.slice";
import auth from "./auth.slice";
import budgets from "./budget.slice";
import calendar from "./calendar.slice";
import catalogSettings from "./catalog-settings.slice";
import catalog from "./catalog.slice";
import eventGroupList from "./event-group-slice";
import eventSettingsSlice from "./event-settings.slice";
import eventBlockSlice from "./events/event-block.slice";
import eventDetails from "./events/event-details.slice";
import eventList from "./events/event-list.slice";
import newEventSlice from "./events/event-modal.slice";
import formikModalSlice from "./formik-modal.slice";
import global from "./globals.slice";
import groupRatesSlice from "./group-rate.slice";
import inventoryLocationsSlice from "./inventory-locations.slice";
import inventoryItemAdjustmentsSlice from "./inventory/inventory-item-adjustments.slice";
import inventoryItemCheckoutCheckinSlice from "./inventory/inventory-item-checkout-checkin.slice";
import inventoryItemSlice from "./inventory/inventory-item-form.slice";
import inventoryItemsListSlice from "./inventory/inventory-item-list.slice";
import inventoryOrderItemsListSlice from "./inventory/inventory-order-item-list.slice";
import inventoryOrder from "./inventory/inventory-order.slice";
import inventoryRequest from "./inventory/inventory-request.slice";
import inventoryPurchaseOrdersSlice from "./inventory/purchase-orders/inventory-purchase-orders.slice";
import unitOfMeasure from "./inventory/unit-of-measure.slice";
import invoicesList from "./invoices.slice";
import localCache from "./local-cache.slice";
import locationAttachmentsSlice from "./location-attachments/location-attachments.slice";
import locations from "./locations.slice";
import newRequestForm from "./new-request-form.slice";
import paymentsList from "./payments.slice";
import budgetFormSlice from "./planning-hq/budgets/budget-form.slice";
import budgetListSlice from "./planning-hq/budgets/budget-list.slice";
import budgetPageSlice from "./planning-hq/budgets/budget-page.slice";
import budgetsPageSlice from "./planning-hq/budgets/budgets-page.slice";
import fundingSourceFormSlice from "./planning-hq/funding-sources/funding-source-form.slice";
import fundingSourceListSlice from "./planning-hq/funding-sources/funding-source-list.slice";
import fundingSourcePageSlice from "./planning-hq/funding-sources/funding-source-page.slice";
import fundingSourcesPageSlice from "./planning-hq/funding-sources/funding-sources-page.slice";
import projectFormSlice from "./planning-hq/project-form";
import projectListSlice from "./planning-hq/project-list";
import projectPageSlice from "./planning-hq/project-page";
import projectsPageSlice from "./planning-hq/projects-page";
import projectBulkAddRequests from "./planning-hq/requests/project-add-bulk-requests";
import projectRequests from "./planning-hq/requests/project-requests";
import projectScheduling from "./planning-hq/scheduling/scheduling.slice";
import projectTimelineSlice from "./planning-hq/timeline/project-timeline";
import eventGroupPortalUsersSlice from "./portal-users.slice";
import qrLookup from "./qr-lookup.slice";
import questionList from "./questions.slice";
import rateSheetSlice from "./rate-sheet.slice";
import reason from "./reason.slice";
import equipments from "./rentable-equipment.slice";
import reportingCategory from "./reporting-category.slice";
import reportsSlice from "./reports/reports.slice";
import requestsColumnViewSlice from "./request-column-view.slice";
import requestsBulkActionsSlice from "./request-form/request-bulk-actions.slice";
import requestForm from "./request-form/request-form.slice";
import requestList from "./request-list.slice";
import requestsSlice from "./requests.slice";
import scheduleRequestList from "./schedule-request-list.slice";
import scheduleRequestForm from "./scheduled-request-form/schedule-request-form.slice";
import schemaEditor from "./schema-editor.slice";
import serviceList from "./service.slice";
import taskbook from "./taskbook.slice";
import timesheet from "./timesheet.slice";
import transactionList from "./transaction-list.slice";
import userGroupForm from "./user-group-form.slice";
import userGroups from "./user-groups.slice";
import phoneVerificationSlice from "./user-phone-verifications";
import vendorSlice from "./vendors/vendors-slice";
import venueSpacesList from "./venue-form.slice";
import venueLevelNotificationList from "./venue-level-notification.slice";
import venueList from "./venue-list.slice";
import workflowAutoAssignRulesList from "./workflow-auto-assign-rules.slice";
import workflowSettings from "./workflow-settings.slice";
import workflow from "./workflow.slice";

const reducer = combineReducers({
  accountUsers,
  aiAssetSlice,
  announcement,
  assetList,
  auth,
  budgets,
  catalog,
  catalogSettings,
  calendar,
  eventBlockSlice,
  eventGroupList,
  eventDetails,
  eventGroupPortalUsersSlice,
  eventList,
  eventSettingsSlice,
  equipments,
  global,
  groupRatesSlice,
  inventoryLocationsSlice,
  inventoryItemSlice,
  inventoryOrder,
  inventoryOrderItemsListSlice,
  inventoryItemsListSlice,
  inventoryItemAdjustmentsSlice,
  inventoryItemCheckoutCheckinSlice,
  inventoryRequest,
  inventoryPurchaseOrdersSlice,
  unitOfMeasure,
  localCache,
  locationAttachmentsSlice,
  newEventSlice,
  newRequestForm,
  qrLookup,
  questionList,
  rateSheetSlice,
  reason,
  reportingCategory,
  requestForm,
  requestList,
  transactionList,
  timesheet,
  serviceList,
  scheduleRequestForm,
  scheduleRequestList,
  schemaEditor,
  taskbook,
  userGroupForm,
  userGroups,
  venueList,
  locations,
  workflow,
  formikModalSlice,
  workflowSettings,
  invoicesList,
  vendorSlice,
  workflowAutoAssignRulesList,
  paymentsList,
  requestsBulkActionsSlice,
  reportsSlice,
  phoneVerificationSlice,
  venueLevelNotificationList,
  venueSpacesList,
  requestsSlice,
  requestsColumnViewSlice,
  projectListSlice,
  projectFormSlice,
  projectPageSlice,
  projectsPageSlice,
  projectTimelineSlice,
  projectRequests,
  projectBulkAddRequests,
  projectScheduling,
  fundingSourceListSlice,
  fundingSourcesPageSlice,
  fundingSourcePageSlice,
  fundingSourceFormSlice,
  budgetListSlice,
  budgetsPageSlice,
  budgetFormSlice,
  budgetPageSlice,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();

export default store;
