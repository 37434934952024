import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiParsedVendorLineItem,
  ApiPurchaseTransaction,
  ApiPurchaseTransactionLineItem,
  ApiScannedInvoiceTransaction,
  ApiTransactionType,
  ApiVendor,
  ApiWorkflow,
  CreateApiPurchaseTransaction,
  PurchaseType,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";

import { InfoIcon } from "@chakra-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import axios, { AxiosProgressEvent } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Attachment,
  Attachments,
} from "../../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../../../components/form-helpers/DatePickerControl";
import FocusError from "../../../../../components/form-helpers/FocusError";
import { NumberInputControl } from "../../../../../components/form-helpers/NumberInputControl";
import { ProjectBudgetsAutocompleteControl } from "../../../../../components/form-helpers/ProjectBudgetsAutocompleteControl";
import { RadioButtonsControl } from "../../../../../components/form-helpers/RadioButtonsControl";
import { TextInputControl } from "../../../../../components/form-helpers/TextInputControl";
import { VendorAutocompleteControl } from "../../../../../components/form-helpers/VendorsAutocompleteControl";
import { useShowToast } from "../../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../../store";
import {
  addAttachment,
  AttachmentTypeEnum,
  cleanTransactionAttachements,
  removeAttachmentReducer,
  updateAttachment,
} from "../../../../../store/request-form/request-form.slice";
import { createTransaction } from "../../../../../store/request-form/thunks/createTransaction.thunk";
import { createTransactionAttachment } from "../../../../../store/request-form/thunks/createTransactionAttachment.thunk";
import { deleteTransactionAttachment } from "../../../../../store/request-form/thunks/deleteTransactionAttachment.thunk";
import { loadTransactionAttachments } from "../../../../../store/request-form/thunks/loadTransactionAttachments.thunk";
import { loadTransactions } from "../../../../../store/request-form/thunks/loadTransactions.thunk";
import { LineItemsSection } from "../LineItemSection";
import LineItemTotal from "../LineItemTotal";

const radioButtonOptions = [
  { label: "Supplies", schemeColor: "blue", value: PurchaseType.supplies },
  { label: "Vendor", schemeColor: "blue", value: PurchaseType.vendor },
];

const PurchaseFormSchema = (type: PurchaseType) =>
  yup.object().shape({
    type: yup.mixed().oneOf([ApiTransactionType.purchase]),
    purchaseType: yup
      .mixed()
      .oneOf([PurchaseType.vendor, PurchaseType.supplies]),
    vendor: yup.object().required("This field is required").nullable(),
    invoiceNumber: yup
      .string()
      .max(255, "Field is too long")
      .optional()
      .nullable(),
    additionalFees: yup
      .number()
      .max(999999.99, "Field must be less than 999999.99")
      .min(-999999.99, "Field must be greater than 999999.99")
      .optional()
      .nullable(),
    budget: yup
      .object()
      .shape({ id: yup.string().uuid() })
      .optional()
      .nullable(),
  });

export interface VerifyScannedReceiptProps {
  manufacturer?: ApiVendor | null;
  transactionId: string | null;
  handleCloseModal: () => void;
  workingPurchase: UpdateApiPurchaseTransaction | null;
  scannedPurchase?: ApiScannedInvoiceTransaction;
  thumbnailPicture?: Attachment;
  itemList: ApiParsedVendorLineItem[];
}

export const VerifyScannedReceipt: React.FC<VerifyScannedReceiptProps> = ({
  transactionId,
  workingPurchase,
  manufacturer,
  handleCloseModal,
  scannedPurchase,
  thumbnailPicture,
  itemList,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [purchase, setPurchase] = useState<
    CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction
  >();
  const radioBgColor = useColorModeValue("white", "gray.700");
  const [workflowForm, setWorkflowForm] = useState<ApiWorkflow | null>();
  const [itemsList, setItemsList] = useState<ApiPurchaseTransactionLineItem[]>(
    itemList.length > 0
      ? itemList.map((item) => ({
          id: "",
          description: item.description,
          quantity: item.quantity,
          unitCost: item.unitCost,
          amount: item.quantity * item.unitCost,
        }))
      : [{ id: "", description: "", quantity: 0, unitCost: 0, amount: 0 }]
  );
  const [purchaseTotal, setPurchaseTotal] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [shipping, setShipping] = useState<number>(
    scannedPurchase?.shipping || 0
  );
  const [isUseScannedTotal, setisUseScannedTotal] = useState<boolean>(true);
  const [tax, setTax] = useState<number>(scannedPurchase?.tax || 0);
  const [additionalFees, setAdditionalFees] = useState<number>(
    scannedPurchase?.additionalFees || 0
  );
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const showToast = useShowToast();
  const { request, workflow } = useSelector(
    (state: RootState) => state.requestForm
  );
  const { workflows } = useSelector((state: RootState) => state.localCache);
  const { workingTransactionAttachments } = useSelector(
    (state: RootState) => state.requestForm.transactions
  );

  const defaultPurchaseType = useMemo(() => {
    return workflow ? workflow.defaultExpenseType : PurchaseType.vendor;
  }, [workflow]);

  const [purchaseType, setPurchaseType] = useState(
    workingPurchase?.purchaseType || defaultPurchaseType
  );
  const purchaseInitValues: CreateApiPurchaseTransaction = useMemo(
    () => ({
      type: ApiTransactionType.purchase,
      purchaseType: scannedPurchase?.purchaseType || defaultPurchaseType,
      checkNumber: scannedPurchase?.checkNumber
        ? parseInt(scannedPurchase.checkNumber)
        : null,
      paymentDate: scannedPurchase?.paymentDate || "",
      datePerformed: scannedPurchase?.datePerformed || "",
      vendor: manufacturer || null,
      description: "Invoice Receipt",
      invoiceNumber: scannedPurchase?.invoiceNumber || null,
      quantity: 0,
      unitCost: 0,
      additionalFees: scannedPurchase?.additionalFees || 0,
      budget: request ? request.budget : null,
      lineItems: itemsList,
      tax: scannedPurchase?.tax || 0,
      shipping: scannedPurchase?.shipping || 0,
      total: scannedPurchase?.totalAmount || 0,
    }),
    [request, defaultPurchaseType, manufacturer, scannedPurchase, itemsList]
  );
  useEffect(() => {
    const total = subTotal + shipping + tax + additionalFees;
    setPurchaseTotal(total);
  }, [subTotal, shipping, tax, additionalFees]);

  useEffect(() => {
    if (workingPurchase !== null) {
      setPurchase(workingPurchase);
    } else {
      setPurchase(purchaseInitValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingPurchase]);

  useEffect(() => {
    if (workingPurchase !== null && transactionId && request) {
      thunkDispatch(
        loadTransactionAttachments({
          apiClient,
          accountId: currentAccount.id,
          requestIdOrKey: request?.id,
          transactionId: transactionId,
        })
      );
    }
    return () => {
      dispatch(cleanTransactionAttachements());
    };
  }, [
    thunkDispatch,
    dispatch,
    workingPurchase,
    apiClient,
    currentAccount,
    request,
    transactionId,
  ]);

  useEffect(() => {
    if (!workingPurchase) {
      setWorkflowForm(workflow);
      return;
    }
    const key = workingPurchase.requestKey?.split("-");
    if (key && key.length) {
      const slug = key[0];
      const workflowTransaction = workflows.find(
        (work) => work.requestSlug === slug
      );
      setWorkflowForm(workflow ? workflow : workflowTransaction);
      return;
    }
    setWorkflowForm(workflow ? workflow : undefined);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWorkflowForm]);

  const onSubmitForm = useCallback(
    async (
      values: CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction,
      actions: FormikHelpers<
        CreateApiPurchaseTransaction | UpdateApiPurchaseTransaction
      >
    ) => {
      setIsSaving(true);
      const purchaseValues = values as CreateApiPurchaseTransaction;
      if (!request) {
        return;
      }
      if (
        isUseScannedTotal &&
        (!purchaseValues.total || purchaseValues.total <= 0)
      ) {
        showToast("error", "Please add a total amount");
        setIsSaving(false);
        return;
      }
      const itemsToValidate = itemsList.filter(
        (item) => item.description.trim() !== "" || item.amount > 0
      );
      if (
        !itemsToValidate.every(
          (item) => item.description.trim() !== "" && item.amount > 0
        )
      ) {
        showToast("error", "Ensure all Items are fully filled out");
        setIsSaving(false);
        return;
      }
      //eslint-disable-next-line
      const lastItems = itemsList.pop();
      try {
        const response = await thunkDispatch(
          createTransaction({
            apiClient,
            account: currentAccount,
            request,
            transaction: {
              type: ApiTransactionType.purchase,
              checkNumber: purchaseValues.checkNumber,
              paymentDate: purchaseValues.paymentDate,
              purchaseType: purchaseValues.purchaseType,
              vendor: purchaseValues.vendor,
              //Obsolete Fields
              description: "General Expense",
              quantity: 1,
              unitCost: purchaseValues.total
                ? parseFloat(purchaseValues.total.toString())
                : 0,
              //End Obsolete Fields
              additionalFees:
                purchaseValues.additionalFees != null
                  ? purchaseValues.purchaseType === PurchaseType.supplies
                    ? parseFloat(purchaseValues.additionalFees.toString())
                    : 0
                  : 0,
              datePerformed:
                purchaseValues.datePerformed || new Date().toISOString(),
              invoiceNumber: purchaseValues.invoiceNumber,
              budget: purchaseValues.budget,
              tax: purchaseValues.tax
                ? parseFloat(purchaseValues.tax.toString())
                : 0,
              shipping: purchaseValues.shipping
                ? parseFloat(purchaseValues.shipping.toString())
                : 0,
              lineItems: itemsToValidate,
              total: isUseScannedTotal
                ? purchaseValues.total
                  ? parseFloat(purchaseValues.total.toString())
                  : 0
                : purchaseTotal,
            },
          })
        ).then(unwrapResult);

        const createdPurchase = response as ApiPurchaseTransaction;
        const newAttachments = workingTransactionAttachments.filter(
          (x) => !!x.uploadId
        );

        const attachmentPromises = newAttachments.map(async (attachment) =>
          thunkDispatch(
            createTransactionAttachment({
              apiClient,
              accountId: currentAccount.id,
              requestIdOrKey: request.id,
              transactionId: createdPurchase.id,
              attachment: {
                // newAttachments is filtered already, typescript doesnt follow
                uploadId: attachment.uploadId!,
                name: attachment.name,
              },
            })
          ).then(unwrapResult)
        );

        await Promise.all(attachmentPromises);
        thunkDispatch(
          loadTransactions({
            apiClient,
            account: currentAccount,
            key: request.key,
            transactionType: "purchase",
          })
        );
        handleCloseModal();
        showToast("success", "All transactions created successfully.");
        actions.setSubmitting(false);
        setIsSaving(false);
      } catch {
        showToast("error", "Error creating transactions");
        actions.setSubmitting(false);
        setIsSaving(false);
      }
    },
    [
      apiClient,
      currentAccount,
      itemsList,
      showToast,
      request,
      handleCloseModal,
      thunkDispatch,
      workingTransactionAttachments,
      purchaseTotal,
      isUseScannedTotal,
    ]
  );

  const savePurchaseAttachment = useCallback(
    (files: Attachment[]) => {
      if (request) {
        files.forEach(async (file) => {
          if (!file.file) return;

          const loadResponse = await apiClient
            .createUpload(currentAccount.id)
            .then(async (uploadedFile) => {
              const newAttachment: Attachment = {
                ...file,
                isUploading: true,
                uploadId: uploadedFile.id,
                progress: 0,
              };

              dispatch(
                addAttachment({
                  ...newAttachment,
                  attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                })
              );
              return { newAttachment, uploadedFile };
            })
            .then(async ({ newAttachment, uploadedFile }) => {
              return axios
                .put(uploadedFile.url, newAttachment.file, {
                  headers: { "Content-type": newAttachment.file?.type },
                  onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
                    dispatch(
                      updateAttachment({
                        ...newAttachment,
                        progress: Math.round(
                          (progressEvent.loaded * 100) /
                            (progressEvent.total || 1)
                        ),
                        attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                      })
                    );
                  },
                })
                .then(() => ({ newAttachment, uploadedFile }));
            })
            .then(({ newAttachment, uploadedFile }) => {
              const updated: Attachment = {
                ...newAttachment,
                isUploading: false,
                progress: undefined,
              };

              dispatch(
                updateAttachment({
                  ...updated,
                  attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
                })
              );
              return Promise.resolve({
                attachment: newAttachment,
                uploadedFile,
              });
            })
            .catch((error) => {
              showToast("error", "Could not load files");
            });

          if (
            transactionId &&
            loadResponse &&
            loadResponse.attachment.uploadId
          ) {
            thunkDispatch(
              createTransactionAttachment({
                apiClient,
                accountId: currentAccount.id,
                requestIdOrKey: request.id,
                transactionId: transactionId,
                attachment: {
                  uploadId: loadResponse.attachment.uploadId,
                  name: loadResponse.attachment.name,
                },
              })
            )
              .then(unwrapResult)
              .catch(() => {
                showToast("error", "Couldn't save attachment");
              });
          }
        });
      }
    },
    [
      apiClient,
      currentAccount,
      transactionId,
      request,
      thunkDispatch,
      dispatch,
      showToast,
    ]
  );

  const handleNewAttachments = useCallback(
    (files: Attachment[]) => {
      savePurchaseAttachment(files);
    },
    [savePurchaseAttachment]
  );

  const handleRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      if (request && attachment.uploadId) {
        if (transactionId) {
          await thunkDispatch(
            deleteTransactionAttachment({
              apiClient,
              accountId: currentAccount.id,
              requestIdOrKey: request.id,
              transactionId: transactionId,
              id: attachment.uploadId,
            })
          )
            .then(unwrapResult)
            .catch((error) => {
              showToast("error", "Error Deleting Purchase Attachment");
            });
        }

        dispatch(
          removeAttachmentReducer({
            ...attachment,
            attachmentType: AttachmentTypeEnum.REQUEST_TRANSACTION,
          })
        );
      }
    },
    [
      apiClient,
      currentAccount,
      transactionId,
      request,
      thunkDispatch,
      dispatch,
      showToast,
    ]
  );

  useEffect(() => {
    if (thumbnailPicture) handleNewAttachments([thumbnailPicture]);
  }, [thumbnailPicture, handleNewAttachments]);

  return (
    <Box>
      {purchase && (
        <Formik
          onSubmit={onSubmitForm}
          initialValues={purchase}
          validationSchema={PurchaseFormSchema(purchaseType)}
        >
          {(props) => {
            return (
              <Form>
                <Grid>
                  <GridItem>
                    <RadioButtonsControl
                      label="Type of purchase"
                      value={purchaseType}
                      name="purchaseType"
                      radioOptions={radioButtonOptions}
                      setLocalState={(value) =>
                        setPurchaseType(value as PurchaseType)
                      }
                      cleanValues={[["vendor", null]]}
                    />
                  </GridItem>
                  <GridItem pt={1}>
                    <HStack>
                      <VendorAutocompleteControl
                        name="vendor"
                        label="Vendor"
                        addVendorLink
                        aiSearch={
                          purchase.vendor
                            ? undefined
                            : (!manufacturer &&
                                scannedPurchase?.manufacturer) ||
                              undefined
                        }
                        value={
                          purchase.vendor
                            ? (purchase.vendor as ApiVendor)
                            : null
                        }
                        isServiceProvider={
                          props.values.purchaseType === PurchaseType.vendor
                        }
                        isSupplier={
                          props.values.purchaseType === PurchaseType.supplies
                        }
                      />
                      {!manufacturer && (
                        <Box mt={6}>
                          <Tooltip
                            label="AI is unconfident or couldn't find"
                            aria-label="A tooltip"
                          >
                            <InfoIcon color="blue.500" />
                          </Tooltip>
                        </Box>
                      )}
                    </HStack>
                  </GridItem>
                  <GridItem pt={1}>
                    <HStack>
                      <NumberInputControl
                        label="Check Number"
                        name="checkNumber"
                        value={purchase?.checkNumber || 0}
                      />
                      {!scannedPurchase?.checkNumber && (
                        <Box mt={6}>
                          <Tooltip
                            label="AI is unconfident or couldn't find"
                            aria-label="A tooltip"
                          >
                            <InfoIcon color="blue.500" />
                          </Tooltip>
                        </Box>
                      )}
                    </HStack>
                  </GridItem>

                  <LineItemsSection
                    value={itemsList}
                    setValues={setItemsList}
                  />

                  <HStack pt={2} display="flex">
                    <Box as="span"> Subtotal </Box>
                    <Spacer />
                    <LineItemTotal
                      lineItems={itemsList}
                      setSubTotal={setSubTotal}
                    />
                  </HStack>

                  <Divider mt={1} mb={2} />

                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4} pt={2}>
                    <GridItem pt={0} colSpan={2}>
                      <HStack>
                        <NumberInputControl
                          label="Additional Fees"
                          name="additionalFees"
                          value={additionalFees}
                          prefix="$"
                          min={-999999.99}
                          precision={2}
                          onChange={(value: number) => {
                            setAdditionalFees(parseFloat(value.toString()));
                          }}
                        />
                        {!scannedPurchase?.additionalFees && (
                          <Box mt={6}>
                            <Tooltip
                              label="AI is unconfident or couldn't find"
                              aria-label="A tooltip"
                            >
                              <Tooltip
                                label="AI is unconfident or couldn't find"
                                aria-label="A tooltip"
                              >
                                <InfoIcon color="blue.500" />
                              </Tooltip>
                            </Tooltip>
                          </Box>
                        )}
                      </HStack>
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4} pt={2}>
                    <GridItem pt={0} colSpan={2}>
                      <HStack>
                        <NumberInputControl
                          label="Tax"
                          name="tax"
                          value={tax}
                          prefix="$"
                          precision={2}
                          onChange={(value: number) =>
                            setTax(parseFloat(value.toString()))
                          }
                        />
                        {!scannedPurchase?.tax && (
                          <Box mt={6}>
                            <Tooltip
                              label="AI is unconfident or couldn't find"
                              aria-label="A tooltip"
                            >
                              <InfoIcon color="blue.500" />
                            </Tooltip>
                          </Box>
                        )}
                      </HStack>
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4} pt={2}>
                    <GridItem pt={0} colSpan={2}>
                      <HStack>
                        <NumberInputControl
                          label="Shipping Cost"
                          name="shipping"
                          value={shipping}
                          prefix="$"
                          precision={2}
                          onChange={(value: number) =>
                            setShipping(parseFloat(value.toString()))
                          }
                        />
                        {!scannedPurchase?.shipping && (
                          <Box mt={6}>
                            <Tooltip
                              label="AI is unconfident or couldn't find"
                              aria-label="A tooltip"
                            >
                              <InfoIcon color="blue.500" />
                            </Tooltip>
                          </Box>
                        )}
                      </HStack>
                    </GridItem>
                  </Grid>

                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4} pt={2}>
                    <GridItem pt={0} colSpan={2}>
                      {!isUseScannedTotal ? (
                        <HStack>
                          <Box as="span"> Total: </Box>
                          <Spacer />
                          <Text fontWeight="normal">
                            {purchaseTotal.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "USD",
                            })}
                          </Text>
                        </HStack>
                      ) : (
                        <NumberInputControl
                          label="Total"
                          name="total"
                          value={purchaseTotal || 0}
                          prefix="$"
                          precision={2}
                        />
                      )}
                    </GridItem>
                  </Grid>
                  <Box mt={2}>
                    <RadioGroup
                      defaultValue="true"
                      onChange={(value) =>
                        setisUseScannedTotal(value === "true")
                      }
                    >
                      <Stack direction="row" spacing={5}>
                        <Radio value="false" backgroundColor={radioBgColor}>
                          Auto-Calculated Total
                        </Radio>
                        <Radio value="true" backgroundColor={radioBgColor}>
                          Scanned Total
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>

                  <Divider mt={1} mb={2} />

                  <Grid templateColumns={"repeat(2, 2fr)"} gap={4}>
                    <GridItem
                      colSpan={[2, null, null, 1]}
                      pt={[4, null, null, 4]}
                      maxW={180}
                    >
                      <HStack>
                        <DatePickerControl
                          value={purchase.datePerformed || null}
                          name="datePerformed"
                          label="Date of purchase"
                        />
                        {!scannedPurchase?.datePerformed && (
                          <Box mt={6}>
                            <Tooltip
                              label="AI is unconfident or couldn't find"
                              aria-label="A tooltip"
                            >
                              <InfoIcon color="blue.500" />
                            </Tooltip>
                          </Box>
                        )}
                      </HStack>
                    </GridItem>
                    <GridItem
                      colSpan={[2, null, null, 1]}
                      pt={[0, null, null, 4]}
                      maxW={180}
                    >
                      {scannedPurchase?.paymentDate ? (
                        <DatePickerControl
                          value={purchase?.paymentDate || null}
                          name="paymentDate"
                          label="Payment Date"
                        />
                      ) : (
                        <HStack>
                          <DatePickerControl
                            value={purchase?.paymentDate || null}
                            name="paymentDate"
                            label="Payment Date"
                          />
                          <Box mt={6}>
                            <Tooltip
                              label="AI is unconfident or couldn't find"
                              aria-label="A tooltip"
                            >
                              <InfoIcon color="blue.500" />
                            </Tooltip>
                          </Box>
                        </HStack>
                      )}
                    </GridItem>
                  </Grid>
                  <GridItem pt={4}>
                    <HStack>
                      <TextInputControl
                        label="PO / Invoice Number"
                        name="invoiceNumber"
                        value={purchase?.invoiceNumber || ""}
                        placeholder="ex: 345 67-AB"
                      />
                      {!scannedPurchase?.invoiceNumber && (
                        <Box mt={6}>
                          <Tooltip
                            label="AI is unconfident or couldn't find"
                            aria-label="A tooltip"
                          >
                            <InfoIcon color="blue.500" />
                          </Tooltip>
                        </Box>
                      )}
                    </HStack>
                  </GridItem>
                  {workflowForm && workflowForm.allowBudgetsOnTransactions && (
                    <GridItem pt={4}>
                      {request && request.projectId ? (
                        <ProjectBudgetsAutocompleteControl
                          value={(purchase?.budget as ApiBudgetSummary) ?? null}
                          label="Credit from budget"
                          name="budget"
                          projectId={request.projectId}
                          placeholder="Search existing budgets"
                          helperText="This expense will pull from this budget."
                        />
                      ) : (
                        <BudgetAutocompleteControl
                          label="Credit from budget"
                          name="budget"
                          value={(purchase?.budget as ApiBudgetSummary) || null}
                          placeholder="Search existing budgets"
                          helperText="This expense will pull from this budget."
                        />
                      )}
                    </GridItem>
                  )}
                  <GridItem pt={4}>
                    <Attachments
                      attachments={workingTransactionAttachments}
                      onDeleteAttachment={handleRemoveAttachment}
                      onNewAttachments={handleNewAttachments}
                      gridColumns={2}
                    />
                  </GridItem>
                  <Divider my={4} />
                  <GridItem textAlign="center">
                    <Button
                      type="submit"
                      size="sm"
                      height="36px"
                      width="200px"
                      variant="solid"
                      colorScheme="blue"
                      isLoading={isSaving}
                    >
                      Add Purchase
                    </Button>
                  </GridItem>
                  <FocusError />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};
