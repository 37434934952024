import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";

const RequestsDashboard = lazy(
  () => import("../../landing-page/requests/LandingPage")
);
const EventsDashboard = lazy(
  () => import("../../landing-page/events/EventsLandingPage")
);

export const useDashboardRoutes = () => {
  const { isProductAdmin, isEventAdmin } = useAuthentication();

  const { eventsHandler, isReviewer, isAdmin, isViewer } = useSelector(
    (state: RootState) => state.localCache
  );

  const { isVenueManager } = eventsHandler.venueManagers;
  const routes: React.ReactNode[] = [];

  if (isProductAdmin || isAdmin || isReviewer || isViewer) {
    routes.push(
      <Route
        path="dashboard/herohq"
        key="routes::dashboard/herohq"
        element={<RequestsDashboard />}
      />
    );
  }

  if (isEventAdmin || isVenueManager) {
    routes.push(
      <Route
        path="dashboard/eventhq"
        key="routes::dashboard/eventhq"
        element={<EventsDashboard />}
      />
    );
  }

  return { routes };
};
