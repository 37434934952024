import { Badge, BadgeProps, useBreakpointValue } from "@chakra-ui/react";
import {
  ApiEventStatus,
  ApiGroupStatus,
  ApiInventoryOrderStatus,
  ApiInventoryPurchaseOrderStatus,
  ApiInventoryRequestItemStatus,
  ApiInventoryRequestStatus,
  ApiInvoiceStatus,
  ApiProjectStatus,
  ApiRequestStatus,
} from "@operations-hero/lib-api-client";
import { TaskbookStatus } from "../../pages/request-form/taskbooks/taskbook-workview/TaskbookWorkView";

export interface StatusBadgeProps {
  status:
    | ApiRequestStatus
    | ApiEventStatus
    | ApiInvoiceStatus
    | TaskbookStatus
    | ApiInventoryRequestStatus
    | ApiInventoryRequestItemStatus
    | ApiProjectStatus
    | ApiInventoryOrderStatus
    | ApiGroupStatus
    | ApiInventoryPurchaseOrderStatus;
  colorScheme?: string;
  badgeProps?: BadgeProps;
}

export const StatusBadge = ({
  status,
  colorScheme,
  badgeProps,
}: StatusBadgeProps) => {
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  return (
    <Badge
      variant={badgeProps?.variant || "outline"}
      fontSize={isTablet ? "xs" : "sm"}
      colorScheme={colorScheme || "gray"}
      {...badgeProps}
    >
      {status}
    </Badge>
  );
};
