import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  NumberInput,
  NumberInputField,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiInventoryItem,
  ApiInventoryItemStorageLocation,
  ApiIssuanceTransaction,
  ApiItemAdjustmentReason,
  ApiItemAdjustmentType,
  ApiLocationSummary,
  ApiRequest,
  ApiUnitOfMeasureSummary,
  ApiUserSummary,
  ApiVendor,
  CreateApiInventoryItemAdjustment,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { InventoryItemLocationSelectControl } from "../../../components/form-helpers/InventoryItemLocationSelectControl";
import { InventoryTypeAutocompleteControl } from "../../../components/form-helpers/InventoryTypeSelectControl";
import { LocationAutocompleteControl } from "../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../components/form-helpers/NumberInputControl";
import { ProjectBudgetsAutocompleteControl } from "../../../components/form-helpers/ProjectBudgetsAutocompleteControl";
import { RequestAutocompleteControl } from "../../../components/form-helpers/RequestAutocompleteControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { UserAutocompleteControl } from "../../../components/form-helpers/UserAutocompleteControl";
import { useShowToast } from "../../../hooks/showToast";
import { useThunkDispatch } from "../../../store";
import { loadInventoryItems } from "../../../store/inventory/inventory-item-list.slice";
import { setTotalInventoryChange } from "../../../store/request-form/request-form.slice";
import { changeQuantityFormSchema } from "../../inventory/inventory-items/quantity/ChangeQuantirySchema";

export interface InventoryIssuanceFormValues {
  item: string;
  itemLocation: ApiInventoryItemStorageLocation;
  type: ApiItemAdjustmentType;
  quantity: number;
  units: ApiUnitOfMeasureSummary;
  requester: ApiUserSummary | null;
  date: Date;
  supplier: ApiVendor | null;
  assignedTo: ApiUserSummary | null;
  budget: ApiBudgetSummary | null;
  notes: string | null;
  purchaseOrder: string | null;
  reasonType: ApiItemAdjustmentReason | null;
  request: ApiRequest | null;
  invoiceNumber: string | null;
  locationFrom: ApiInventoryItemStorageLocation | null;
  locationTo: ApiInventoryItemStorageLocation | null;
  deliveryLocation: ApiLocationSummary | null;
}

interface InventryIssuanceFormProps {
  onClose: () => void;
  item: ApiInventoryItem;
  request: ApiRequest;
  transaction?: ApiIssuanceTransaction;
}

export const InventoryIssuanceForm: FC<InventryIssuanceFormProps> = ({
  onClose,
  item,
  request,
  transaction,
}) => {
  const [formSchema, setFormSchema] = useState(
    changeQuantityFormSchema(ApiItemAdjustmentType.issued)
  );

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { apiClient, currentAccount, currentUser } = useAuthentication();

  const initialValues: InventoryIssuanceFormValues | null = useMemo(() => {
    const [firstLocation] = item.storageLocations;

    const values: InventoryIssuanceFormValues = {
      item: item.id,
      itemLocation: firstLocation,
      type: ApiItemAdjustmentType.issued,
      quantity: transaction ? transaction.inventoryItemAdjustment.quantity : 0,
      units: item.units,
      requester: transaction
        ? transaction.inventoryItemAdjustment.requester
        : currentUser,
      date: transaction
        ? new Date(transaction.inventoryItemAdjustment.date)
        : new Date(),
      assignedTo: transaction
        ? transaction.inventoryItemAdjustment.assignedTo
        : currentUser,
      budget: transaction
        ? transaction.inventoryItemAdjustment.budget
        : request.budget,
      request: request,
      notes: transaction ? transaction.inventoryItemAdjustment.notes : null,
      supplier: transaction
        ? (transaction.inventoryItemAdjustment.supplier as ApiVendor)
        : null,
      purchaseOrder: transaction
        ? transaction.inventoryItemAdjustment.purchaseOrder
        : null,
      reasonType: transaction
        ? transaction.inventoryItemAdjustment.reason
        : null,
      locationTo: transaction
        ? transaction.inventoryItemAdjustment.itemLocation
        : null,
      locationFrom: null,
      invoiceNumber: transaction
        ? transaction.inventoryItemAdjustment.invoiceNumber
        : null,
      deliveryLocation: null,
    };
    return values;
  }, [item, currentUser, request, transaction]);

  const mapValues = useCallback(
    (formValues: InventoryIssuanceFormValues) => {
      const values: CreateApiInventoryItemAdjustment = {
        item: formValues.item,
        itemLocation: formValues.itemLocation.id,
        type: formValues.type,
        quantity: formValues.quantity,
        unitCost: null,
        units: formValues.units,
        inventoryAdjustmentToCheckOut: null,
        date: new Date(formValues.date).toISOString(),
        returnDate: null,
        notes: formValues.notes,
        requestId: formValues.request ? formValues.request.id : null,
        requester: formValues.requester
          ? formValues.requester.id
          : currentUser.id,
        purchaseOrder: formValues.purchaseOrder,
        invoiceNumber: formValues.invoiceNumber,
        serialNumber: null,
        assignedTo: formValues.assignedTo,
        budget: formValues.budget ? formValues.budget.id : null,
        supplier: formValues.supplier,
        reason: formValues.reasonType,
        deliveryLocationId: formValues.deliveryLocation
          ? formValues.deliveryLocation.id
          : null,
        issuanceTransactionId: null,
        status: null,
        associatedAdjustmentId: null,
        bulkCheckinItems: null,
      };
      return values;
    },
    [currentUser.id]
  );

  const handleCreateAdjustment = useCallback(
    (values: CreateApiInventoryItemAdjustment) => {
      apiClient
        .createInventoryItemAdjustments(currentAccount.id, values)
        .then(() => {
          showToast("success", "Adjustment was created successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );
          dispatch(setTotalInventoryChange(1));
          onClose();
        })
        .catch(() => {
          showToast("error", "something went wrong creating an Adjustment");
        });
    },
    [apiClient, currentAccount.id, onClose, showToast, thunkDispatch, dispatch]
  );

  const handleOnSubmit = useCallback(
    (values: InventoryIssuanceFormValues) => {
      const newValues = mapValues(values);
      handleCreateAdjustment(newValues);
    },
    [handleCreateAdjustment, mapValues]
  );

  if (!initialValues || !item)
    return (
      <Flex flexDir="column" gap={4}>
        <Alert status="warning">
          <AlertIcon />
          To change the quantity of an item, at least one storage location is
          required.
        </Alert>
        <Button
          size="sm"
          maxW="80px"
          variant="outline"
          colorScheme="blue"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    );

  return (
    <VStack alignItems="flex-start">
      <Box w="100%">
        <Formik
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
          validationSchema={formSchema}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                  <GridItem colSpan={[6, 6, 3]}>
                    <InventoryTypeAutocompleteControl
                      name="type"
                      label="Type"
                      value={values.type}
                      shouldResetOnChange
                      setSchema={setFormSchema}
                      isDisabled
                    />
                  </GridItem>

                  <GridItem colSpan={[6, 6, 3]}>
                    <DatePickerControl
                      value={values.date}
                      name="date"
                      label="Date"
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <UserAutocompleteControl
                      value={values.requester}
                      name="requester"
                      label="Requester"
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <InventoryItemLocationSelectControl
                      showQuantity
                      name="itemLocation"
                      value={values.itemLocation}
                      label="Location"
                      inventoryItemId={item.id}
                      catalogId={item.catalog.id}
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <UserAutocompleteControl
                      value={values.assignedTo}
                      name="assignedTo"
                      label="Assigned To"
                    />
                  </GridItem>
                  <GridItem colSpan={6}>
                    <RequestAutocompleteControl
                      name="request"
                      value={values.request}
                      label="Work Order/Request Number"
                      isDisabled={true}
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <LocationAutocompleteControl
                      label="Issued location"
                      name="deliveryLocation"
                      value={values.deliveryLocation}
                    />
                  </GridItem>

                  <GridItem colSpan={3}>
                    <FormControl>
                      <FormLabel>Unit Cost</FormLabel>
                      <NumberInput size="sm" isDisabled value={item.cost}>
                        <NumberInputField minH="42px" borderRadius={4} />
                      </NumberInput>
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={3}>
                    <NumberInputControl
                      name="quantity"
                      label={"Issued Quantity"}
                      value={values.quantity}
                      precision={2}
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    {request.projectId ? (
                      <ProjectBudgetsAutocompleteControl
                        value={values.budget}
                        label="Budget"
                        name="budget"
                        projectId={request.projectId}
                      />
                    ) : (
                      <BudgetAutocompleteControl
                        value={values.budget}
                        label="Budget"
                        name="budget"
                      />
                    )}
                  </GridItem>

                  <GridItem colSpan={6}>
                    <TextEditorControl
                      value={values.notes}
                      name="notes"
                      label="Notes"
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <Divider />
                  </GridItem>

                  <GridItem
                    colSpan={6}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button size="sm" colorScheme="blue" type="submit">
                      Submit Change
                    </Button>
                  </GridItem>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </VStack>
  );
};
