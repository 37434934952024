import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiLocation } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import React, { LegacyRef, useCallback, useMemo, useReducer } from "react";
import Select, {
  SelectMethods,
  SelectProps,
  SelectRenderer,
  SelectState,
} from "react-dropdown-select";
import { useSelector } from "react-redux";
import type { ListRowProps } from "react-virtualized";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { RootState } from "../../store";
import { buildMap } from "../../utils/buildMap";
import { useLocationUtils } from "../../utils/locationUtils";
import { AdditionalNumberBadge } from "../badges/AdditionalNumberBadge";
import { LocationTwoLine } from "../badges/LocationTwoLine";

export interface LocationFilterValue {
  value: ApiLocation[];
  l: string[];
  el: string[];
  lastChecked?: string;
  clearSelectAll?: boolean;
}

export interface LocationFilterProps {
  value: (ApiLocation | string)[];
  onChange: (values: LocationFilterValue) => void;
  allowedLocations?: ApiLocation[];
  productName?: Products;
  l?: string[];
  el?: string[];
}

interface VirtualizedListProps {
  props: SelectProps<ApiLocation>;
  methods: SelectMethods<ApiLocation>;
  state: SelectState<ApiLocation>;
  selectionState: LocationFilterReducerState;
  reducer: React.Dispatch<
    | {
        type: "add" | "remove";
        location: ApiLocation;
      }
    | {
        type: "clear" | "selectall";
      }
  >;
}

const VirtualizedList = ({
  props,
  methods,
  state,
  selectionState,
  reducer,
}: VirtualizedListProps) => {
  const rows = state.search
    ? //@ts-ignore
      (state.searchResults as ApiLocation[])
    : props.options;

  const cellCache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
    });
  }, []);

  return (
    <Box style={{ height: "250px" }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cellCache}
            rowHeight={cellCache.rowHeight}
            rowCount={rows.length}
            rowRenderer={(rowProps) => (
              <RowRenderer
                {...rowProps}
                props={props}
                methods={methods}
                rows={rows}
                cellCache={cellCache}
                selectionState={selectionState}
                reducer={reducer}
              />
            )}
            containerStyle={{
              overflow: "visible",
            }}
          />
        )}
      </AutoSizer>
    </Box>
  );
};

const RowRenderer = ({
  parent,
  index,
  style,
  rows,
  cellCache,
  methods,
  props,
  selectionState,
  reducer,
}: ListRowProps & {
  rows: ApiLocation[];
  cellCache: CellMeasurerCache;
  methods: SelectMethods<ApiLocation>;
  props: SelectProps<ApiLocation>;
  selectionState: LocationFilterReducerState;
  reducer: React.Dispatch<
    | {
        type: "add" | "remove";
        location: ApiLocation;
      }
    | {
        type: "clear" | "selectall";
      }
  >;
}) => {
  const rowLocation = useMemo(() => rows[index] as ApiLocation, [rows, index]);
  const uniqueKey = useMemo(
    () => `loc-filter-${rowLocation.id}`,
    [rowLocation.id]
  );

  const isChecked = useMemo(() => {
    return selectionState.selected[rowLocation.id] ? true : false;
  }, [selectionState, rowLocation.id]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const type = e.target.checked ? "add" : "remove";
      reducer({ type, location: rowLocation });
    },
    [reducer, rowLocation]
  );

  const adjustedStyle = {
    ...style,
    display: "flex",
    justifyContent: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "0px",
    paddingRight: "0px",
  };
  return (
    <CellMeasurer
      cache={cellCache}
      key={uniqueKey}
      columnIndex={0}
      rowIndex={index}
      parent={parent}
    >
      {({ registerChild }) => (
        //https://github.com/bvaughn/react-virtualized/issues/1572
        <Box
          key={uniqueKey}
          ref={registerChild as LegacyRef<HTMLDivElement> | undefined}
          style={adjustedStyle}
        >
          <Checkbox
            key={rowLocation.id}
            isChecked={isChecked}
            onChange={(e) => {
              methods.addItem(rowLocation); //trigger onChange event for dropdown-select
              onChange(e);
            }}
            w="100%"
            p={1}
          >
            <LocationTwoLine value={rowLocation} />
          </Checkbox>
        </Box>
      )}
    </CellMeasurer>
  );
};

const ContentRenderer = ({ props }: SelectRenderer<ApiLocation>) => {
  const borderColor = useColorModeValue(undefined, "gray.700");
  return (
    <Box p={1} borderColor={borderColor}>
      {props.values.length === 0 && "Location"}
      {props.values.length === 1 && (
        <Box maxW={"200px"}>
          <LocationTwoLine value={props.values[0]} displayAncestors={false} />
        </Box>
      )}
      {props.values.length > 1 && (
        <>
          <Text mr={1} as="span">
            {props.values[0].name}
          </Text>
          <AdditionalNumberBadge number={props.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  methods,
  state,
  selectionState,
  reducer,
}: SelectRenderer<ApiLocation> & {
  selectionState: LocationFilterReducerState;
  reducer: React.Dispatch<
    | {
        type: "add" | "remove";
        location: ApiLocation;
      }
    | {
        type: "clear" | "selectall";
      }
  >;
}) => {
  const handleClearAll = useCallback(() => {
    reducer({ type: "clear" });
  }, [reducer]);
  const handleSelectAll = useCallback(() => {
    reducer({ type: "selectall" });
  }, [reducer]);

  const areAllSelected = useMemo(() => {
    return props.options.length === Object.keys(selectionState.selected).length;
  }, [props.options, selectionState.selected]);

  return (
    <Box p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <Text>Search and select:</Text>
          {areAllSelected ? (
            <Button size="sm" variant="outline" onClick={handleClearAll}>
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={handleSelectAll}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Locations"
        />
      </Box>
      <Box>
        <VirtualizedList
          props={props}
          methods={methods}
          state={state}
          selectionState={selectionState}
          reducer={reducer}
        />
      </Box>
    </Box>
  );
};

interface LocationFilterReducerState {
  selected: Record<string, ApiLocation>;
  l: string[];
  el: string[];
}

export const LocationFilter = ({
  value,
  onChange,
  allowedLocations,
  productName,
  l,
  el,
}: LocationFilterProps) => {
  const themeClass = useColorModeValue("light-theme", "dark-theme");
  // TODO: allowed locations cleanup
  const { findAllChildrenForNodes, locationHash, sortLocations } =
    useLocationUtils(allowedLocations);

  const { descendantsMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const locationValueReducer = useCallback(
    (
      state: LocationFilterReducerState,
      action:
        | {
            type: "add" | "remove";
            location: ApiLocation;
          }
        | {
            type: "clear" | "selectall";
          }
    ): LocationFilterReducerState => {
      const l = new Set(state.l);
      const el = new Set(state.el);

      switch (action.type) {
        case "add":
          // Add to the included Locations (l)
          // remove all children from excluded locations (el)
          const descandantIds = descendantsMap[action.location.id] || [];
          l.add(action.location.id);
          el.delete(action.location.id);
          //if you add a child from the action, you should remove the parent on the other side??
          /*                     //@ts-ignore
          for (let path of el) {
            if (path.includes(action.location.id)) {
              el.delete(path);
            }
          }  */

          const nodeAndDescendants = descandantIds.reduce<
            Record<string, ApiLocation>
          >(
            (result, child) => {
              const allowed = locationHash[child];
              el.delete(allowed?.id || "");
              if (allowed && allowed.id !== action.location.id) {
                l.delete(allowed?.id || "");
              }
              if (allowed && allowed.active) {
                result[allowed.id] = allowed;
              }
              return result;
            },
            {
              [action.location.id]: action.location, // include the selected node
            }
          );

          return {
            selected: {
              ...state.selected,
              ...nodeAndDescendants,
            },
            l: Array.from(l),
            el: Array.from(el),
          };
        case "remove":
          const newSelected = { ...state.selected };
          delete newSelected[action.location.id];
          // add to exclusion, remove from inclusion_
          if (l.has(action.location.id)) {
            l.delete(action.location.id);
          } else {
            el.add(action.location.id);
          }
          /*           //@ts-ignore
          for (let path of l) {
            if (path.includes(action.location.id)) {
              l.delete(path);
            }
          } */
          const idsToDelete = descendantsMap[action.location.id] || [];
          idsToDelete.forEach((child) => {
            delete newSelected[child];
            const allowed = locationHash[child];
            l.delete(allowed?.id || "");
            if (allowed && allowed.id !== action.location.id) {
              el.delete(allowed?.id || "");
            }
          });
          return {
            selected: newSelected,
            l: Array.from(l),
            el: Array.from(el),
          };
        case "clear":
          return {
            selected: {},
            l: [],
            el: [],
          };
        case "selectall":
          return {
            selected: locationHash,
            l: [],
            el: [],
          };
        default:
          return state;
      }
    },
    [descendantsMap, locationHash]
  );
  const memoValues = useMemo(() => {
    return value
      .map((v) => (typeof v === "string" ? locationHash[v] : v))
      .filter(Boolean);
  }, [value, locationHash]);

  const [selection, dispatchLocationValue] = useReducer(locationValueReducer, {
    selected:
      memoValues?.length > 0
        ? buildMap(memoValues)
        : ({} as Record<string, ApiLocation>),
    l: l || [],
    el: el || [],
  });

  // Inbound => recieved e,l from url should be hydrated into selected
  // Ouput => l, el should be updated during state changes,
  const locations = useMemo(() => {
    const sortedLocations = sortLocations(Object.values(locationHash));
    if (!allowedLocations || allowedLocations?.length === 0) {
      return sortedLocations;
    }
    const remainingLocations = Object.values(locationHash).filter(
      (lh) => !sortedLocations.some((sl) => sl.id === lh.id)
    );
    if (remainingLocations.length > 0)
      return sortLocations(sortedLocations.concat(remainingLocations));
    return sortedLocations;
  }, [allowedLocations, locationHash, sortLocations]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(locations, ["name", "address"], {
        sort: true,
      }),
    [locations]
  );

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiLocation>) => {
      if (!state.search) {
        return locations;
      }

      const found = fuzzySearch.search(state.search);
      return findAllChildrenForNodes(found);
    },
    [locations, fuzzySearch, findAllChildrenForNodes]
  );

  const handleOnChange = useCallback(() => {
    onChange({
      value: Object.values(selection.selected),
      l: selection.l,
      el: selection.el,
    });
  }, [onChange, selection]);

  return (
    <Select
      multi
      options={locations!}
      className={themeClass}
      values={Object.values(selection.selected)}
      onChange={handleOnChange}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      //clearable
      contentRenderer={ContentRenderer}
      dropdownRenderer={(props) => (
        <DropdownRenderer
          {...props}
          selectionState={selection}
          reducer={dispatchLocationValue}
        />
      )}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  );
};
