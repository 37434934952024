import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiInventoryItemStorageLocation,
  ApiItemAdjustmentReason,
  ApiItemAdjustmentType,
  ApiLocationSummary,
  ApiRequest,
  ApiUnitOfMeasureSummary,
  ApiUserSummary,
  ApiVendor,
  CreateApiInventoryItemAdjustment,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../../components/form-helpers/DatePickerControl";
import { InventoryAuditReasonSelectControl } from "../../../../components/form-helpers/InventoryAuditReasonSelectControl";
import { InventoryItemLocationSelectControl } from "../../../../components/form-helpers/InventoryItemLocationSelectControl";
import { InventoryTypeAutocompleteControl } from "../../../../components/form-helpers/InventoryTypeSelectControl";
import { LocationAutocompleteControl } from "../../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { ProjectBudgetsAutocompleteControl } from "../../../../components/form-helpers/ProjectBudgetsAutocompleteControl";
import { RequestAutocompleteControl } from "../../../../components/form-helpers/RequestAutocompleteControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { UserAutocompleteControl } from "../../../../components/form-helpers/UserAutocompleteControl";
import { VendorAutocompleteControl } from "../../../../components/form-helpers/VendorsAutocompleteControl";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import { loadInventoryItemAdjustments } from "../../../../store/inventory/inventory-item-adjustments.slice";
import {
  initInventoryItemForm,
  reloadItemStorageLocations,
} from "../../../../store/inventory/inventory-item-form.slice";
import { loadInventoryItems } from "../../../../store/inventory/inventory-item-list.slice";
import { changeQuantityFormSchema } from "./ChangeQuantirySchema";
import { MoveQuantityForm } from "./MoveQuantityForm";

export interface ChangeQuantityFormValues {
  item: string;
  itemLocation: ApiInventoryItemStorageLocation;
  type: ApiItemAdjustmentType;
  quantity: number | null;
  unitCost: number;
  units: ApiUnitOfMeasureSummary;
  requester: ApiUserSummary | null;
  date: Date;
  supplier: ApiVendor | null;
  assignedTo: ApiUserSummary | null;
  budget: ApiBudgetSummary | null;
  notes: string | null;
  purchaseOrder: string | null;
  reasonType: ApiItemAdjustmentReason | null;
  request: ApiRequest | null;
  invoiceNumber: string | null;
  locationFrom: ApiInventoryItemStorageLocation | null;
  locationTo: ApiInventoryItemStorageLocation | null;
  deliveryLocationId: ApiLocationSummary | null;
}

interface ChangeQuantityFormProps {
  onClose: () => void;
}

export const ChangeQuantityForm: FC<ChangeQuantityFormProps> = ({
  onClose,
}) => {
  const { workingItem } = useSelector(
    (state: RootState) => state.inventoryItemsListSlice
  );
  const { item, includeInactiveLocations } = useSelector(
    (state: RootState) => state.inventoryItemSlice
  );
  const { workflowMap } = useSelector((state: RootState) => state.localCache);
  const [formSchema, setFormSchema] = useState(
    changeQuantityFormSchema(ApiItemAdjustmentType.restock)
  );

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, currentAccount, currentUser } = useAuthentication();

  const initialValues: ChangeQuantityFormValues | null = useMemo(() => {
    if (!workingItem || !workingItem.storageLocations[0]) return null;
    const [firstLocation] = workingItem.storageLocations.filter(
      (location) =>
        location.id !== "" && location.active && location.storageLocation.active
    );

    const values: ChangeQuantityFormValues = {
      item: workingItem.id,
      itemLocation: firstLocation,
      type: ApiItemAdjustmentType.restock,
      quantity: null,
      unitCost: workingItem.cost,
      units: workingItem.units,
      requester: currentUser,
      date: new Date(),
      assignedTo: null,
      budget: null,
      request: null,
      notes: null,
      supplier: null,
      purchaseOrder: null,
      reasonType: null,
      locationTo: null,
      locationFrom: firstLocation,
      invoiceNumber: null,
      deliveryLocationId: null,
    };
    return values;
  }, [workingItem, currentUser]);

  const mapValues = useCallback(
    (formValues: ChangeQuantityFormValues) => {
      const values: CreateApiInventoryItemAdjustment = {
        item: formValues.item,
        itemLocation: formValues.itemLocation.id,
        type: formValues.type,
        quantity: formValues.quantity || 0,
        unitCost:
          formValues.type === ApiItemAdjustmentType.restock
            ? formValues.unitCost
            : null,
        units: formValues.units,
        inventoryAdjustmentToCheckOut: null,
        date: formValues.date.toISOString(),
        returnDate: null,
        notes: formValues.notes,
        requestId: formValues.request ? formValues.request.id : null,
        requester: formValues.requester
          ? formValues.requester.id
          : currentUser.id,
        purchaseOrder: formValues.purchaseOrder,
        invoiceNumber: formValues.invoiceNumber,
        serialNumber: null,
        assignedTo: formValues.assignedTo,
        budget: formValues.budget ? formValues.budget.id : null,
        supplier: formValues.supplier,
        reason: formValues.reasonType,
        deliveryLocationId: formValues.deliveryLocationId
          ? formValues.deliveryLocationId.id
          : null,
        issuanceTransactionId: null,
        status: null,
        associatedAdjustmentId: null,
        bulkCheckinItems: null,
      };
      return values;
    },
    [currentUser.id]
  );

  const handleCreateAdjustment = useCallback(
    (values: CreateApiInventoryItemAdjustment) => {
      apiClient
        .createInventoryItemAdjustments(currentAccount.id, values)
        .then(() => {
          showToast("success", "Adjustment was created successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );

          if (item.id) {
            if (values.type === ApiItemAdjustmentType.restock) {
              thunkDispatch(
                initInventoryItemForm({
                  apiClient,
                  accountId: currentAccount.id,
                  itemId: item.id,
                })
              );
            }
            thunkDispatch(
              loadInventoryItemAdjustments({
                apiClient,
                accountId: currentAccount.id,
                itemId: item.id,
              })
            );
            thunkDispatch(
              reloadItemStorageLocations({
                apiClient,
                accountId: currentAccount.id,
                itemId: item.id,
                includeInactive: !includeInactiveLocations,
              })
            );
          }
          onClose();
        })
        .catch(() => {
          showToast("error", "something went wrong creating an Adjustment");
        });
    },
    [
      apiClient,
      currentAccount.id,
      onClose,
      showToast,
      thunkDispatch,
      includeInactiveLocations,
      item,
    ]
  );

  const handleCreateMoveAdjustment = useCallback(
    (values: ChangeQuantityFormValues) => {
      const newValues = mapValues(values);
      apiClient
        .createMoveInventoryItemAdjustments(currentAccount.id, {
          ...newValues,
          itemLocationFrom: values.locationFrom ? values.locationFrom.id : "",
          itemLocationTo: values.locationTo ? values.locationTo.id : "",
        })
        .then(() => {
          showToast("success", "Adjustment was created successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );
          onClose();
        })
        .catch(() => {
          showToast("error", "something went wrong creating an Adjustment");
        });
    },
    [apiClient, currentAccount.id, mapValues, onClose, showToast, thunkDispatch]
  );

  const handleOnSubmit = useCallback(
    (values: ChangeQuantityFormValues) => {
      const newValues = mapValues(values);
      if (values.type !== ApiItemAdjustmentType.move) {
        handleCreateAdjustment(newValues);
      } else {
        handleCreateMoveAdjustment(values);
      }
    },
    [handleCreateAdjustment, handleCreateMoveAdjustment, mapValues]
  );

  const calculateTotal = useCallback((values: ChangeQuantityFormValues) => {
    const quantity = values.quantity || 0;
    if (values.type === ApiItemAdjustmentType.audit) {
      return (
        (quantity - values.itemLocation.quantity) *
        values.unitCost
      ).toFixed(2);
    }
    return (quantity * values.unitCost).toFixed(2);
  }, []);

  const getQuantityLabel = useCallback((type: ApiItemAdjustmentType | null) => {
    if (!type) return "";
    if (type === ApiItemAdjustmentType.issued) return "Issued";
    if (type === ApiItemAdjustmentType.move) return "Move";
    if (type === ApiItemAdjustmentType.restock) return "Added";
    if (type === ApiItemAdjustmentType.audit) return "New";

    return "";
  }, []);

  if (!initialValues || !workingItem)
    return (
      <Flex flexDir="column" gap={4}>
        <Alert status="warning">
          <AlertIcon />
          To change the quantity of an item, at least one storage location is
          required.
        </Alert>
        <Button
          size="sm"
          maxW="80px"
          variant="outline"
          colorScheme="blue"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    );

  return (
    <VStack alignItems="flex-start">
      <Box w="100%">
        <Formik
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
          validationSchema={formSchema}
        >
          {({ values }) => {
            const quantityLabel = getQuantityLabel(values.type);
            return (
              <Form>
                <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                  <GridItem colSpan={[6, 6, 3]}>
                    <InventoryTypeAutocompleteControl
                      name="type"
                      label="Type"
                      value={values.type}
                      shouldResetOnChange
                      setSchema={setFormSchema}
                    />
                  </GridItem>

                  <GridItem colSpan={[6, 6, 3]}>
                    <DatePickerControl
                      value={values.date}
                      name="date"
                      label="Date"
                    />
                  </GridItem>

                  {values.type === ApiItemAdjustmentType.move && (
                    <>
                      {workingItem.storageLocations.length === 1 ? (
                        <GridItem
                          colSpan={6}
                          display="flex"
                          flexDir="column"
                          gap={4}
                        >
                          <Alert status="warning">
                            <AlertIcon />
                            To move items, at least two storage location are
                            required.
                          </Alert>
                          <Button
                            size="sm"
                            maxW="80px"
                            variant="outline"
                            colorScheme="blue"
                            onClick={onClose}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      ) : (
                        <MoveQuantityForm
                          workingItem={workingItem}
                          onClose={onClose}
                        />
                      )}
                    </>
                  )}

                  {values.type !== ApiItemAdjustmentType.move && (
                    <>
                      <GridItem colSpan={6}>
                        <UserAutocompleteControl
                          value={values.requester}
                          name="requester"
                          label="Requester"
                        />
                      </GridItem>

                      {values.type === ApiItemAdjustmentType.restock && (
                        <>
                          <GridItem colSpan={6}>
                            <VendorAutocompleteControl
                              isSupplier
                              isSupplierOnly
                              value={values.supplier}
                              name="supplier"
                              label="Supplier"
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <TextInputControl
                              value={values.purchaseOrder}
                              name="purchaseOrder"
                              label="PO Number"
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <TextInputControl
                              label="Invoice Number"
                              name="invoiceNumber"
                              value={values.invoiceNumber}
                              placeholder="ex: 345 67-AB"
                            />
                          </GridItem>
                        </>
                      )}

                      <GridItem colSpan={6}>
                        <InventoryItemLocationSelectControl
                          showQuantity
                          name="itemLocation"
                          value={values.itemLocation}
                          label="Storage Location"
                          inventoryItemId={workingItem?.id}
                          catalogId={workingItem?.catalog.id}
                        />
                      </GridItem>

                      {values.type === ApiItemAdjustmentType.issued && (
                        <>
                          <GridItem colSpan={6}>
                            <UserAutocompleteControl
                              value={values.assignedTo}
                              name="assignedTo"
                              label="AssignedTo"
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <RequestAutocompleteControl
                              name="request"
                              value={values.request}
                              label="Work Order/Request Number"
                              allowEmpty
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <LocationAutocompleteControl
                              label="Issued location"
                              name="deliveryLocationId"
                              value={values.deliveryLocationId}
                            />
                          </GridItem>
                        </>
                      )}

                      {values.type === ApiItemAdjustmentType.audit && (
                        <GridItem colSpan={6}>
                          <InventoryAuditReasonSelectControl
                            label="Reason"
                            name="reasonType"
                            value={values.reasonType}
                            hideUnselectable
                          />
                        </GridItem>
                      )}

                      <GridItem colSpan={3}>
                        <NumberInputControl
                          min={0}
                          name="quantity"
                          placeholder="Add Quantity"
                          label={`${quantityLabel} Quantity`}
                          value={values.quantity}
                          precision={2}
                        />
                      </GridItem>

                      <GridItem colSpan={3}>
                        <NumberInputControl
                          prefix="$"
                          label={"Unit Cost"}
                          name="unitCost"
                          isDisabled={
                            values.type !== ApiItemAdjustmentType.restock
                          }
                          value={workingItem?.cost}
                        />
                      </GridItem>

                      {(values.type === ApiItemAdjustmentType.issued ||
                        (values.request &&
                          workflowMap[values.request.workflow.id]
                            .allowBudgetsOnTransactions)) && (
                        <GridItem colSpan={6}>
                          {values.request?.projectId ? (
                            <ProjectBudgetsAutocompleteControl
                              value={values.budget}
                              label="Budget"
                              name="budget"
                              projectId={values.request.projectId}
                              allowEmpty
                            />
                          ) : (
                            <BudgetAutocompleteControl
                              value={values.budget}
                              label="Budget"
                              name="budget"
                              allowEmpty
                            />
                          )}
                        </GridItem>
                      )}

                      <GridItem colSpan={6}>
                        <TextEditorControl
                          value={values.notes}
                          name="notes"
                          label="Notes"
                        />
                      </GridItem>

                      <GridItem colSpan={6}>
                        <Heading fontSize="xl" textAlign="right">
                          {`Total Cost: $${calculateTotal(values)}`}
                        </Heading>
                      </GridItem>

                      <GridItem colSpan={6}>
                        <Divider />
                      </GridItem>

                      <GridItem
                        colSpan={6}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme="blue"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button size="sm" colorScheme="blue" type="submit">
                          Submit Change
                        </Button>
                      </GridItem>
                    </>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </VStack>
  );
};
