import { Box } from "@chakra-ui/layout";
import { ApiBudget, ApiBudgetSummary } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import {
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { BudgetBadge } from "../badges/BudgetBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  BudgetOptionpProp,
  BudgetSingleValueSelect,
  CustomSelectComponentProp,
} from "./select-overrides-types";

export interface ProjectBudgetsAutocompleteProps {
  projectId: string;
  value: ApiBudget | ApiBudgetSummary | null;
  onChange: (value: ApiBudget | ApiBudgetSummary | null) => void;
  name?: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  isClearable?: boolean;
}

const CustomOptionComponent = createOptionComponent(
  ({ value }: { value: ApiBudget | ApiBudgetSummary }) => (
    <Box alignContent="center">
      <BudgetBadge value={value} />
    </Box>
  )
);

const CustomSingleValueComponent = (props: BudgetSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps} maxW="95%" minW={0}>
      <BudgetBadge value={data} />
    </Box>
  );
};

export const ProjectBudgetsAutocomplete = ({
  projectId,
  onChange,
  onBlur,
  name,
  placeholder,
  value,
  isDisabled,
  isClearable,
}: ProjectBudgetsAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [defaultOptions, setDefaultOptions] = useState<
    Array<ApiBudget | ApiBudgetSummary>
  >([]);

  const handleChange = useCallback(
    (newValue: ApiBudget | ApiBudgetSummary | null) => {
      onChange(newValue);
    },
    [onChange]
  );

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findProjectBudgets(currentAccount.id, projectId, {
          search: inputValue ?? "",
          pageSize: 10,
        })
        .then((results) => {
          const projectBudgets = results.data;
          const budgets = projectBudgets.map(
            (prjBdg) => prjBdg.budget
          ) as ApiBudget[];
          cb(budgets);
        });
    },
    [apiClient, currentAccount, projectId]
  );

  const loadDefaultOptions = useCallback(async () => {
    const projectBudgetsResult = await apiClient.findProjectBudgets(
      currentAccount.id,
      projectId,
      {
        pageSize: 10,
      }
    );

    const budgets = projectBudgetsResult.data.map(
      (prjBdg) => prjBdg.budget
    ) as ApiBudget[];

    setDefaultOptions(budgets);
  }, [projectId, apiClient, currentAccount.id]);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: BudgetOptionpProp) => CustomOptionComponent(props),
      SingleValue: (props: BudgetSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  useEffect(() => {
    loadDefaultOptions();
  }, [loadDefaultOptions]);

  return (
    <AsyncSelect
      cacheOptions={true}
      components={components}
      defaultOptions={defaultOptions}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder={placeholder || "Budget code or keyword"}
      value={value}
      getOptionValue={(item: ApiBudgetSummary | ApiBudget) => item.id}
      isClearable={isClearable}
      chakraStyles={commonStyles}
    />
  );
};
